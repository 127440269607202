import { render, staticRenderFns } from "./salesTrend.vue?vue&type=template&id=2c17f7aa&scoped=true&"
import script from "./salesTrend.vue?vue&type=script&lang=js&"
export * from "./salesTrend.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c17f7aa",
  null
  
)

export default component.exports